<script>
import api from '@/helpers/api-rest/api.js';

export default {
    props:{
        project: {type: Object, default: () => {
            return {
                name: '',
                description: '',
                link: '',
                project_status_id: '',
                project_type_id: '',
                status: '',
            }
        }}
    },
    data() {
        return {
            selectOptionsProjectType: [],
        };
    },
    computed: {
        modalTitle() {
        return this.project?.id ? 'Editar Convocatoria' : 'Añadir Convocatoria';
        },
        submitButtonText() {
            return this.project?.id ? 'Guardar' : 'Añadir Convocatoria';
        },
    },
    created() {
        this.loadSelectOptionsProjectType();
    },
    methods: {
        loadSelectOptionsProjectType(){
            const apiEndpoint = `/api/v1/project-types`;
            api.get({url:apiEndpoint})
            .then(response => {
                this.selectOptionsProjectType = response?.data?.data;
            })
            .catch(error => {
                console.error("Error al cargar los tipos de convocatorias:", error);
            });
        },
        createProject() {
            const apiEndpoint = `/api/v1/projects`; 
            api.post({url:apiEndpoint, data:this.project})
            //axios.post(apiEndpoint, this.user)
                .then(response => {
                    const newProject = response?.data?.data;
                    this.$emit('success', newProject);
                    this.$bvModal.hide("ModalProject");
                })
                .catch(error => {
                    console.error("Error al crear la convocatoria:", error);
                });
        },

        updateProject() {
            const apiEndpoint = `/api/v1/projects`;
            api.put({url:apiEndpoint, data:this.project, id:this.project.id})
            .then(response => {
                const updatedProject = response?.data?.data;
                this.$emit('success', updatedProject);
                this.$bvModal.hide("ModalProject");
            })
            .catch(error => {
                console.error("Error editing convocatoria:", error);
            });
        },

        submitForm() {
            if(this.project?.id){
                return this.updateProject();
            }
            return this.createProject();
        },
        cancelForm() {
            this.$bvModal.hide("ModalProject");
            this.$emit('close-modal');
        }
    }
}
</script>
<template>
    <b-modal id="ModalProject" size="lg" :title="modalTitle">  
      <template #default="{  }">
        <form @submit.prevent="submitForm" enctype="multipart/form-data">
            <div class="form-group">
                <label for="name">Nombre:</label>
                <input v-model="project.name" type="text" class="form-control" id="name" required>
            </div>
            <div class="form-group">
                <label for="projectdesc">Descripcion</label>
                <textarea v-model="project.description" class="form-control" id="projectdesc" rows="3" required></textarea>
            </div>
            <div class="form-group">
                <label for="selectedProjectType">Tipo de convocatoria:</label>
                <select v-model="project.project_type_id" class="form-control" id="selectedProjectType" required>
                    <option value="">Selecciona tipo</option>
                    <option v-for="option in selectOptionsProjectType" :value="option.id" :key="option.id">{{ option.name }}</option>
                </select>
            </div>
            <div class="form-group">
                <label for="name">Enlace de la convocatoria:</label>
                <input v-model="project.link" type="text" class="form-control" id="link" required>
            </div>
        </form>
      </template>
  
      <template #modal-footer="{  }">
        <b-button variant="primary" @click="submitForm()">{{ submitButtonText }}</b-button>
        <b-button @click="cancelForm()">Cancel</b-button>
      </template>
    </b-modal>
</template>
