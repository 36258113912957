<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
//import axios from 'axios';
import ProjectModal from '@/components/modals/project-modal.vue';
import api from '@/helpers/api-rest/api.js';
import store from '@/state/store';

export default {
    page: {
        title: "Convocatorias",
        meta: [{ name: "description", content: appConfig.description }]
    },
    components: { 
        Layout, 
        PageHeader,
        ProjectModal
    },
  data() {
    return {
      tableData: [],
      title: "Convocatorias",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      fields: [
        { key: "id", sortable: false, label: "#" },
        { key: "name", sortable: true, label: "Nombre" },
        { key: "description", sortable: true, label: "Descripción" },
        { key: "project_type_id", sortable: false, label: "Tipo" },
        { key: "status", sortable: false, label: "Estado" },
        { key: "action", tdClass:"custom-table-action",  sortable: false, label: "" }
      ],
      editMode: false,
      projectEdit: undefined,
      essays_number: 0
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    }
  },
  methods: {
    showElement(roleId = []) {
      const role = store.getters['auth/role'];
      if (roleId.includes(role.id)) {
        return true;
      }
      return false;
    },
    loadDataFromApi(){
      const apiEndpoint = `/api/v1/projects`;
      api.get({url:apiEndpoint})
        .then(response => {
          const data = response?.data?.data;
          data.forEach(project => {
            project.essays_number = project.essays ? project.essays.length : 0;
          });

          // Almacenar los datos en tableData
          this.tableData = data;

        })
        .catch(error => {
          console.error("Error loading data:", error);
        });
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    success(project) {
      console.log(project);
      this.loadDataFromApi();
    },

    addProject() {
      this.projectEdit = undefined;
    },

    editProject(project) {
        project.role_ids = project?.roles?.map(item => (item?.id));
        this.projectEdit = project;
    },
    updateFields() {
      // Si el usuario es administrador, añade al array de campos la columna Redacciones
      if (this.showElement([1])) {
        this.fields.splice(3, 0, { key: "numberEssays", sortable: false, label: "Redacciones" });
      }

      
    }

  },
  mounted() {
    this.loadDataFromApi();
    // Set the initial number of items
    this.totalRows = this.tableData.length;
    this.updateFields();
  },
}
</script>

<template>
  <Layout>
    <PageHeader :title="title">
      <template v-slot:header-button>
        <button v-if="showElement([1])" v-b-modal.ModalProject @click="addProject()" class="btn btn-success">
          <i class="fa fa-plus"></i> Añadir Convocatoria
        </button>
      </template>
    </PageHeader>
    <ProjectModal :project="projectEdit" @success="success" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="card-title">Data Table</h4> -->
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Mostrar&nbsp;
                    <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;registros
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Buscar..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :items="tableData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                show-empty
                ref="projectTable"
              >
                <template v-slot:cell(project_type_id)="row">
                  <div v-if="row.item.project_type_id">
                    <b-badge pill :variant="row.item.project_type_id === 1 ? 'info' : 'light'" class="ml-1">
                      {{ row.item.project_type.name }}
                    </b-badge>
                  </div>
                </template>
                <template v-slot:cell(numberEssays)="row">
                  <div class="text-center">
                    <b-badge pill variant="light">
                      {{ row.item.essays_number }}
                    </b-badge>
                  </div>
                </template>
                <template v-slot:cell(status)="row">
                  <div>
                    <b-badge pill :variant="row.item.status === 1 ? 'success' : 'danger'" class="ml-1">
                      {{ row.item.status === 1 ? 'Activo' : 'Inactivo' }}
                    </b-badge>
                  </div>
                </template>
                <template v-slot:cell(action)="row">
                  <div>
                    <a v-if="showElement([1])" href="javascript:void(0);" class="mr-3 text-secondary" v-b-modal.ModalProject title="Edit" @click="editProject(row.item)">
                      <i class="mdi mdi-square-edit-outline font-size-18"></i>
                    </a>
                    <router-link class="mr-3 text-secondary" :to="{ name: 'project-detail', params: { id: row.item.id }}">
                      <i class="mdi mdi-eye-outline font-size-18"></i>
                    </router-link>
                    <a v-if="row.item.link" :href="row.item.link" class="mr-3 text-secondary" title="Enlace a la convocatoria" target="_blank">
                      <i class="mdi mdi-web font-size-18"></i>
                    </a>
                  </div>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>